import { Controller } from '@hotwired/stimulus'
import * as CookieConsent from 'vanilla-cookieconsent'

export default class extends Controller {
  static values = {
    locale: String,
  }

  async connect () {
    await this.initializeCookieConsent()
  }

  async initializeCookieConsent () {
    await CookieConsent.reset(false)
    await CookieConsent.run({
      root: '#cookie-consent',
      autoClearCookies: true,
      guiOptions: {
        consentModal: {
          layout: 'box wide',
          position: 'bottom left',
          equalWeightButtons: true,
          flipButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
          equalWeightButtons: true,
          flipButtons: true,
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: true,
          autoClear: {
            cookies: [
              {
                name: /^_ga/,
              },
              {
                name: /^_ga_#/,
              },
            ],
          },
          services: {
            ga: {
              label: 'Google Analytics',
              onAccept: () => {},
              onReject: () => {},
            },
          },
        },
        ads: {
          enabled: true,
          autoClear: {
            cookies: [
              {
                name: /^_gcl_au/,
              },
              {
                name: /^_fbp/,
              },
              {
                name: 'fr',
              },
              {
                name: 'locale',
              },
            ],
          },
          services: {
            ga: {
              label: 'Google Adsense',
              onAccept: () => {},
              onReject: () => {},
            },
            fb: {
              label: 'Facebook',
              onAccept: () => {},
              onReject: () => {},
            },
          },
        },
      },
      language: {
        default: this.localeValue,
        translations: {
          en: {
            consentModal: {
              title: 'Accept Cookies',
              description: 'We use cookies to provide our services. By using this website, you agree to this.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage preferences',
              footer: `<a href='/${this.localeValue}/cookie-policy'>Cookies Policy</a>`,
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              serviceCounterLabel: 'Service|Services',
              sections: [
                {
                  title: 'Your Privacy Choices',
                  description: `This panel provides you with the ability to manage and customize the way we process your personal information. It's here you have the liberty to make informed decisions and explicitly set your preferences. Should you wish to make adjustments in the future, you can simply revisit this panel through the link we've made available. If there are certain processes outlined below to which you'd like to withhold consent, simply set the corresponding toggles to 'off' or activate the "Reject all" button. Remember to confirm to save your updated selections.`,
                },
                {
                  title: 'Strictly Necessary',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                  linkedCategory: 'necessary',
                  cookieTable: {
                    caption: 'Information',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domain',
                      desc: 'Description',
                    },
                    body: [
                      {
                        name: 'rc::a',
                        domain: location.hostname,
                        desc: 'Used to track and analyze user behavior to distinguish humans from bots or automated software.',
                      },
                      {
                        name: 'guest_token',
                        domain: location.hostname,
                        desc: 'Used to identify and track guest users on the website for the purpose of maintaining session states across pages',
                      },
                      {
                        name: 'product-options',
                        domain: location.hostname,
                        desc: 'Used to store user-selected product customization options for a consistent shopping experience across different pages of the website.',
                      },
                      {
                        name: '_project_session',
                        domain: location.hostname,
                        desc: 'Used to maintain user session information across multiple pages during a single visit to the website.',
                      },
                      {
                        name: 'lastExternalReferrer',
                        domain: location.hostname,
                        desc: 'Used to store the URL of the last external page that led the user to this website. Helps in analyzing traffic sources.',
                      },
                      {
                        name: 'lastExternalReferrerTime',
                        domain: location.hostname,
                        desc: 'Used to store the timestamp of when the user last arrived at this website from an external source. Assists in tracking user navigation behavior.',
                      },
                    ],
                  },
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics',
                  cookieTable: {
                    caption: 'Information',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domain',
                      desc: 'Description',
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: location.hostname,
                        desc: 'Records a particular ID used to come up with data about website usage by the user',
                      },
                      {
                        name: '_ga_#',
                        domain: location.hostname,
                        desc: 'Used to distinguish individual users by means of designation of a randomly generated number as client identifier, which allows calculation of visits and sessions',
                      },
                    ],
                  },
                },
                {
                  title: 'Targeting and Advertising',
                  description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                  linkedCategory: 'ads',
                  cookieTable: {
                    caption: 'Information',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domain',
                      desc: 'Description',
                    },
                    body: [
                      {
                        name: '_gcl_au',
                        domain: location.hostname,
                        desc: 'Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.',
                      },
                      {
                        name: 'fbp',
                        domain: location.hostname,
                        desc: 'Facebook tracking pixel used to identify visitors for personalized advertising.',
                      },
                      {
                        name: 'fr',
                        domain: location.hostname,
                        desc: 'Used by Facebook to collect a unique browser and user ID, used for targeted advertising.',
                      },
                      {
                        name: 'locale',
                        domain: location.hostname,
                        desc: 'This cookie contains the display locale of the last logged in user on this browser.',
                      },
                    ],
                  },
                },
              ],
            },
          },
          pt: {
            consentModal: {
              title: 'Aceitar Cookies',
              description: 'Utilizamos cookies para a prestação de serviços. Ao utilizar este website, está a concordar com isso.',
              acceptAllBtn: 'Aceitar todos',
              acceptNecessaryBtn: 'Rejeitar todos',
              showPreferencesBtn: 'Gerir preferências',
              footer: `<a href='/${this.localeValue}/cookie-policy'>Política de Cookies</a>`,
            },
            preferencesModal: {
              title: 'Gerir preferências de cookies',
              acceptAllBtn: 'Aceitar todos',
              acceptNecessaryBtn: 'Rejeitar todos',
              savePreferencesBtn: 'Aceitar seleção atual',
              closeIconLabel: 'Fechar modal',
              serviceCounterLabel: 'Serviço|Serviços',
              sections: [
                {
                  title: 'Escolhas de Privacidade',
                  description: `Este painel permite-lhe gerir e personalizar a forma como processamos as suas informações pessoais. Aqui tem a liberdade de tomar decisões informadas e definir explicitamente as suas preferências. Se, no futuro, desejar fazer ajustes nas definições, pode simplesmente voltar a este painel através do link que disponibilizamos. Se houver algum processo específico, entre os descritos abaixo, para o qual pretenda recusar o consentimento, basta desativar os botões correspondentes ou ativar o botão "rejeitar todos". No final, confirme as alterações, para guardar a atualização das definições selecionadas.`,
                },
                {
                  title: 'Estritamente necessário',
                  description: 'Estes cookies são essenciais para o normal funcionamento do website e não podem ser desativados.',
                  linkedCategory: 'necessary',
                  cookieTable: {
                    caption: 'Informações',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domínio',
                      desc: 'Descrição',
                    },
                    body: [
                      {
                        name: 'rc::a',
                        domain: location.hostname,
                        desc: 'Utilizado para acompanhar e analisar o comportamento do utilizador de forma a distinguir humanos de bots ou softwares automatizados.',
                      },
                      {
                        name: 'guest_token',
                        domain: location.hostname,
                        desc: 'Utilizado para acompanhar e identificar utilizadores que acedem ao website, com o objetivo de manter estados de sessão através das páginas.',
                      },
                      {
                        name: 'opções do produto',
                        domain: location.hostname,
                        desc: 'Utilizado para armazenar opções de personalização de produtos selecionadas pelo utilizador, proporcionando uma experiência de compra consistente em diferentes páginas do website.',
                      },
                      {
                        name: '_sessão do projeto',
                        domain: location.hostname,
                        desc: 'Utilizado para manter as informações da sessão do utilizador nas várias páginas durante uma única visita ao website.',
                      },
                      {
                        name: 'lastExternalReferrer',
                        domain: location.hostname,
                        desc: 'Utilizado para armazenar o URL da última página externa que levou o utilizador a este website. Ajuda na análise das fontes de tráfego.',
                      },
                      {
                        name: 'lastExternalReferrerTime',
                        domain: location.hostname,
                        desc: 'Utilizado para armazenar os dados de data/hora da última vez que o utilizador acedeu a este website a partir de uma fonte externa. Auxilia na análise do comportamento de navegação do utilizador.',
                      },
                    ],
                  },
                },
                {
                  title: 'Desempenho e Análise',
                  description: 'Estes cookies recolhem informações sobre como utiliza o nosso website. Todos os dados são anonimizados e não podem ser utilizados para o identificar.',
                  linkedCategory: 'analytics',
                  cookieTable: {
                    caption: 'Informações',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domínio',
                      desc: 'Descrição',
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: location.hostname,
                        desc: 'Regista um ID específico usado para gerar dados sobre a utilização do website pelo utilizador.',
                      },
                      {
                        name: '_ga_#',
                        domain: location.hostname,
                        desc: 'Usado para distinguir utilizadores individuais por meio da atribuição de um número aleatório como identificador de cliente, o que permite calcular números de visitas e de sessões.',
                      },
                    ],
                  },
                },
                {
                  title: 'Segmentação e Publicidade',
                  description: 'Estes cookies são utilizados para tornar as mensagens publicitárias mais adequadas aos seus interesses. A intenção é exibir anúncios que sejam mais relevantes, direcionados aos interesses do utilizador e, portanto, mais valiosos para os editores e outros anunciantes.',
                  linkedCategory: 'ads',
                  cookieTable: {
                    caption: 'Informação',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domínio',
                      desc: 'Descrição',
                    },
                    body: [
                      {
                        name: '_gcl_au',
                        domain: location.hostname,
                        desc: 'Utilizado pelo Google AdSense para testar a eficiência da publicidade em vários websites que utilizam os seus serviços.',
                      },
                      {
                        name: 'fbp',
                        domain: location.hostname,
                        desc: 'Píxel de rastreamento do Facebook utilizado para identificar visitantes através de publicidade personalizada.',
                      },
                      {
                        name: 'fr',
                        domain: location.hostname,
                        desc: 'Utilizado pelo Facebook para recolher um identificador único relativo ao browser e ao utilizador, para publicidade direcionada.',
                      },
                      {
                        name: 'locale',
                        domain: location.hostname,
                        desc: 'Este cookie contém as configurações de exibição do último utilizador que iniciou sessão neste navegador.',
                      },
                    ],
                  },
                },
              ],
            },
          },
          es: {
            consentModal: {
              title: 'Aceptar Cookies',
              description: 'Utilizamos cookies para la prestación de servicios. Al utilizar este sitio web, usted está aceptando esto.',
              acceptAllBtn: 'Aceptar todos',
              acceptNecessaryBtn: 'Rechazar todos',
              showPreferencesBtn: 'Gestionar preferencias',
              footer: `<a href='/${this.localeValue}/cookie-policy'>Política de Cookies</a>`,
            },
            preferencesModal: {
              title: 'Gestión de preferencias de cookies',
              acceptAllBtn: 'Aceptar todas',
              acceptNecessaryBtn: 'Rechazar todas',
              savePreferencesBtn: 'Aceptar selección actual',
              closeIconLabel: 'Cerrar modal',
              serviceCounterLabel: 'Servicio|Servicios',
              sections: [
                {
                  title: 'Tus opciones de privacidad',
                  description: `Este panel le permite gestionar y personalizar la forma en que procesamos su información personal. Aquí tiene la libertad de tomar decisiones informadas y establecer explícitamente sus preferencias. Si en el futuro desea realizar ajustes en la configuración, simplemente puede volver a este panel a través del enlace que proporcionamos. Si hay algún proceso específico, entre los descritos a continuación, para el cual desee rechazar el consentimiento, simplemente desactive los botones correspondientes o active el botón "rechazar todos". Finalmente, confirme los cambios para guardar la actualización de las preferencias seleccionadas.`,
                },
                {
                  title: 'Estrictamente necesario',
                  description: 'Estas cookies son esenciales para el funcionamiento normal del sitio web y no se pueden desactivar.',
                  linkedCategory: 'necessary',
                  cookieTable: {
                    caption: 'Información',
                    headers: {
                      name: 'Cookie',
                      domain: 'Dominio',
                      desc: 'Descripción',
                    },
                    body: [
                      {
                        name: 'rc::a',
                        domain: location.hostname,
                        desc: 'Utilizado para rastrear y analizar el comportamiento del usuario con el fin de distinguir entre humanos y bots o software automatizado.',
                      },
                      {
                        name: 'guest_token',
                        domain: location.hostname,
                        desc: 'Utilizado para seguir e identificar a los usuarios que acceden al sitio web, con el objetivo de mantener estados de sesión a través de las páginas.',
                      },
                      {
                        name: 'opciones del producto',
                        domain: location.hostname,
                        desc: 'Utilizado para almacenar opciones de personalización de productos seleccionadas por el usuario, proporcionando una experiencia de compra consistente en diferentes páginas del sitio web.',
                      },
                      {
                        name: '_sesión del proyecto',
                        domain: location.hostname,
                        desc: 'Utilizado para mantener la información de sesión del usuario en varias páginas durante una única visita al sitio web.',
                      },
                      {
                        name: 'lastExternalReferrer',
                        domain: location.hostname,
                        desc: 'Utilizado para almacenar la URL de la última página externa que llevó al usuario a este sitio web. Ayuda en el análisis de las fuentes de tráfico.',
                      },
                      {
                        name: 'lastExternalReferrerTime',
                        domain: location.hostname,
                        desc: 'Utilizado para almacenar los datos de fecha/hora de la última vez que el usuario accedió a este sitio web desde una fuente externa. Ayuda en el análisis del comportamiento de navegación del usuario.',
                      },
                    ],
                  },
                },
                {
                  title: 'Desempeño y Análisis',
                  description: 'Estas cookies recopilan información sobre cómo utiliza nuestro sitio web. Todos los datos son anonimizados y no pueden ser utilizados para identificarlo.',
                  linkedCategory: 'analytics',
                  cookieTable: {
                    caption: 'Información',
                    headers: {
                      name: 'Cookie',
                      domain: 'Dominio',
                      desc: 'Descripción',
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: location.hostname,
                        desc: 'Registra un ID específico utilizado para generar datos sobre el uso del sitio web por parte del usuario.',
                      },
                      {
                        name: '_ga_#',
                        domain: location.hostname,
                        desc: 'Utilizado para distinguir usuarios individuales mediante la asignación de un número aleatorio como identificador de cliente, lo que permite calcular números de visitas y sesiones.',
                      },
                    ],
                  },
                },
                {
                  title: 'Segmentación y Publicidad',
                  description: 'Estas cookies se utilizan para hacer que los mensajes publicitarios sean más relevantes para sus intereses. La intención es mostrar anuncios que sean más pertinentes, dirigidos a los intereses del usuario y, por lo tanto, más valiosos para los editores y otros anunciantes.',
                  linkedCategory: 'ads',
                  cookieTable: {
                    caption: 'Información',
                    headers: {
                      name: 'Cookie',
                      domain: 'Dominio',
                      desc: 'Descripción',
                    },
                    body: [
                      {
                        name: '_gcl_au',
                        domain: location.hostname,
                        desc: 'Utilizado por Google AdSense para probar la eficacia de la publicidad en varios sitios web que utilizan sus servicios.',
                      },
                      {
                        name: 'fbp',
                        domain: location.hostname,
                        desc: 'Píxel de seguimiento de Facebook utilizado para identificar visitantes a través de publicidad personalizada.',
                      },
                      {
                        name: 'fr',
                        domain: location.hostname,
                        desc: 'Utilizado por Facebook para recoger un identificador único relativo al navegador y al usuario, para publicidad dirigida.',
                      },
                      {
                        name: 'locale',
                        domain: location.hostname,
                        desc: 'Esta cookie contiene la configuración de visualización del último usuario que inició sesión en este navegador.',
                      },
                    ],
                  },
                },
              ],
            },
          },
          fr: {
            consentModal: {
              title: 'Accepter les cookies',
              description: 'Nous utilisons des cookies pour fournir nos services. En utilisant ce site web, vous consentez à cela.',
              acceptAllBtn: 'Accepter tous',
              acceptNecessaryBtn: 'Rejeter tout',
              showPreferencesBtn: 'Gérer les préférences',
              footer: `<a href='/${this.localeValue}/politique-des-cookies'>Politique des cookies</a>`,
            },
            preferencesModal: {
              title: 'Gestion des préférences de cookies',
              acceptAllBtn: 'Accepter tout',
              acceptNecessaryBtn: 'Rejeter tout',
              savePreferencesBtn: 'Accepter la sélection actuelle',
              closeIconLabel: 'Fermer la fenêtre modale',
              serviceCounterLabel: 'Service|Services',
              sections: [
                {
                  title: 'Vos préférences de confidentialité',
                  description: `Ce panneau vous donne la possibilité de gérer et de personnaliser la manière dont nous traitons vos informations personnelles. C'est ici que vous avez la liberté de prendre des décisions éclairées et de définir explicitement vos préférences. Si vous souhaitez apporter des ajustements à l'avenir, vous pouvez simplement revenir sur ce panneau via le lien que nous avons mis à votre disposition. Si certains processus décrits ci-dessous nécessitent votre consentement, il vous suffit de désactiver les boutons correspondants ou d'activer le bouton "rejeter tout". N'oubliez pas de confirmer pour enregistrer vos sélections mises à jour`,
                },
                {
                  title: 'Strictement nécessaire',
                  description: 'Ces cookies sont essentiels au fonctionnement normal du site Web et ne peuvent pas être désactivés.',
                  linkedCategory: 'necessary',
                  cookieTable: {
                    caption: 'Information',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domaine',
                      desc: 'Description',
                    },
                    body: [
                      {
                        name: 'rc::a',
                        domain: location.hostname,
                        desc: "Utilisé pour suivre et analyser le comportement de l'utilisateur afin de distinguer les humains des bots ou des logiciels automatisés.",
                      },
                      {
                        name: 'guest_token',
                        domain: location.hostname,
                        desc: 'Utilisé pour suivre et identifier les utilisateurs qui accèdent au site web, dans le but de maintenir des états de session à travers les pages.',
                      },
                      {
                        name: 'options de produit',
                        domain: location.hostname,
                        desc: "Utilisé pour stocker les options de personnalisation de produits sélectionnées par l'utilisateur, offrant une expérience d'achat cohérente sur différentes pages du site web.",
                      },
                      {
                        name: '_session de projet',
                        domain: location.hostname,
                        desc: "Utilisé pour maintenir les informations de session de l'utilisateur sur plusieurs pages pendant une seule visite sur le site web.",
                      },
                      {
                        name: 'lastExternalReferrer',
                        domain: location.hostname,
                        desc: "Utilisé pour stocker l'URL de la dernière page externe qui a conduit l'utilisateur vers ce site web. Aide à l'analyse des sources de trafic.",
                      },
                      {
                        name: 'lastExternalReferrerTime',
                        domain: location.hostname,
                        desc: "Utilisé pour stocker les données de date/heure de la dernière fois que l'utilisateur a accédé à ce site web depuis une source externe. Aide à l'analyse du comportement de navigation de l'utilisateur.",
                      },
                    ],
                  },
                },
                {
                  title: 'Performance et Analyse',
                  description: 'Ces cookies recueillent des informations sur votre utilisation de notre site web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.',
                  linkedCategory: 'analytics',
                  cookieTable: {
                    caption: 'Information',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domaine',
                      desc: 'Description',
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: location.hostname,
                        desc: "Enregistre un identifiant spécifique utilisé pour générer des données sur l'utilisation du site web par l'utilisateur.",
                      },
                      {
                        name: '_ga_#',
                        domain: location.hostname,
                        desc: "Utilisé pour distinguer les utilisateurs individuels en attribuant un numéro aléatoire comme identifiant de client, ce qui permet de calculer les nombres de visites et de sessions.",
                      },
                    ],
                  },
                },
                {
                  title: 'Ciblage et Publicité',
                  description: "Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vos intérêts. L'intention est de présenter des annonces qui sont plus pertinentes, ciblées sur les intérêts de l'utilisateur et donc plus précieuses pour les éditeurs et autres annonceurs.",
                  linkedCategory: 'ads',
                  cookieTable: {
                    caption: 'Information',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domaine',
                      desc: 'Description',
                    },
                    body: [
                      {
                        name: '_gcl_au',
                        domain: location.hostname,
                        desc: "Utilisé par Google AdSense pour tester l'efficacité de la publicité sur plusieurs sites web utilisant ses services.",
                      },
                      {
                        name: 'fbp',
                        domain: location.hostname,
                        desc: 'Pixel de suivi de Facebook utilisé pour identifier les visiteurs via la publicité personnalisée.',
                      },
                      {
                        name: 'fr',
                        domain: location.hostname,
                        desc: "Utilisé par Facebook pour recueillir un identifiant unique relatif au navigateur et à l'utilisateur, pour la publicité ciblée.",
                      },
                      {
                        name: 'locale',
                        domain: location.hostname,
                        desc: "Ce cookie contient les paramètres d'affichage du dernier utilisateur qui s'est connecté sur ce navigateur.",
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    })
  }
}
